<template>
  <RouterLink v-if="view === 'tile'" :tag="isModal ? 'div' : 'a'" style="cursor: pointer; text-decoration: none;" :class="{ 'selected': selectedFile}" class="uk-card uk-card-default uk-width-small uk-height-small uk-text-center uk-card-hover uk-border-rounded" :event="isModal ? '' : 'click'" :to="isModal ? '' : isMediaBundle ? toMedia : toMediaItem" @click.native="isModal && $emit( 'click' )">
      <div class="uk-margin-small-left uk-margin-small-right">
          <span v-if="!isModal && !isMaster" class="uk-align-right uk-margin-remove" style="cursor: pointer" uk-icon="icon: more" />
          <div v-if="!isModal && !isMaster" style="min-width: 100px;" class="uk-padding-remove" :class="{ 'uk-margin-remove': !isModal }" :id="'delete-dropdown-'+item.id" uk-toggle uk-dropdown="mode: click; pos: bottom-right; animation: uk-animation-slide-top-small; duration: 500; delay-hide: 0 ">
          <ul class="uk-nav uk-dropdown-nav uk-margin-small" style="margin-top: 5px; margin-bottom: 5px">
            <li v-if="showMove" class="uk-text-left uk-margin-small-left uk-text-default"> <span @click.prevent="onClick"> <span uk-icon="icon: folder; ratio:.8" />  <span style="margin-left: 5px">{{$t('Actions.Move')}}</span> </span>  </li>
            <li class="uk-text-left uk-margin-small-left uk-text-default"> <span @click.prevent="deleteFile"> <span  uk-icon="icon: trash; ratio:.8" /> <span style="margin-left: 5px">{{$t('Actions.Delete')}}</span> </span>  </li>
          </ul>
        </div>
        <br>
          <ImgOrFallback v-if="logo" :src="logo" :height="height" :width="width"/> 
          <MediaItemImageFallback v-else :media="mainMedia" />
        <div>
          <p class="uk-text-truncate uk-card-title title" :uk-tooltip="displayTitle"> {{ displayTitle }} <br />
            <span class="uk-text-meta meta-data"> {{typeAndResolutionText}} </span> <br />
            <MediaBadges class="uk-text-meta" :media="mainMedia" />
            <span class="uk-text-meta meta-data"> {{ mainMedia.createdAt | date }} </span>
            <span v-if="hasSize" class="uk-text-meta meta-data "> | {{ getMediaSizeBytes( mainMedia ) | appropriateBytes }} </span>
          </p>
        </div>
      </div>
      <div id="info-modal-media-item" uk-modal="bg-close: false; esc-close: false">
          <div class="uk-modal-dialog uk-modal-body">
              <button class="uk-modal-close-default" type="button" uk-close></button>
                <div class="uk-text-warning" uk-icon="icon: info; ratio: 2" />
              <p class="uk-text-meta">{{ $t('DeleteModal.CannotDeleteMedia') }}</p>
          </div>
        </div>
        <div v-if="show" :id="'media-picker-modal-'+item.id" uk-modal="bg-close: false; esc-close: false">
            <div class="uk-modal-dialog uk-modal-body uk-width-3-4 media-picker-modal">
              <ModalMediaPicker mode="folder" :itemsDisabled="true" :submit="submit" :parent-id="item.parents[0].id" @select="moveToFolder" button-text="Move" @cancel="closeModal"/>
            </div>
        </div>
  </RouterLink>
  <RouterLink v-else :tag="isModal ? 'div' : 'tr'" style="cursor: pointer; text-decoration: none;" :class="{ 'selected': selectedFile === id }" class="uk-items-center" :to="isModal ? '' : isMediaBundle ? toMedia : toMediaItem" @click.native="isModal && $emit( 'click' )">
    <td class="uk-text-center">
      <ImgOrFallback v-if="logo" :src="logo" :height="height" :width="width"/> 
      <MediaItemImageFallback v-else :media="mainMedia" />
    </td>
    <td>
      <p class="uk-text-truncate uk-width-large"> {{ displayTitle }} </p>
    </td>
    <td class="uk-width-auto uk-text-center">
      <span> {{typeAndResolutionText}} </span>
    </td>
    <td class="uk-width-auto uk-text-center">
      <MediaBadges :media="mainMedia" />
    </td>
    <td class="uk-text-center">
      <span  v-if="hasSize"> {{ getMediaSizeBytes( mainMedia ) | appropriateBytes }} </span>
      <span v-else> N/A </span>
    </td>
    <td class="uk-width-small uk-text-center">
      <span> {{ mainMedia.createdAt | date }} </span>
    </td>
    <td class="uk-flex-center uk-flex uk-margin-small-top">
        <div :uk-tooltip="$t('Actions.Move')" @click.stop="onClick" class="uk-margin-small-right"  uk-icon="icon: move; ratio:1"/>
        <div :uk-tooltip="$t('Actions.Delete')" @click.stop="deleteFile"> <span  uk-icon="icon: trash; ratio:0.9" /></div>
    </td>
      <div id="info-modal-media-item" uk-modal="bg-close: false; esc-close: false">
          <div class="uk-modal-dialog uk-modal-body">
              <button class="uk-modal-close-default" type="button" uk-close></button>
                <div class="uk-text-warning" uk-icon="icon: info; ratio: 2" />
              <p class="uk-text-meta">{{ $t('DeleteModal.CannotDeleteMedia') }}</p>
          </div>
        </div>  
    <div v-if="show" :id="'media-picker-modal-'+item.id" uk-modal="bg-close: false; esc-close: false">
      <div class="uk-modal-dialog uk-modal-body uk-width-3-4 media-picker-modal">
        <ModalMediaPicker mode="folder" :itemsDisabled="true" :submit="submit" :parent-id="item.parents[0].id" @select="moveToFolder" button-text="Move" @cancel="closeModal"/>
      </div>
    </div>
  </RouterLink>
</template>

<script>
import { getMediaTranscodeType, getMediaResolutionLabel, isBundle, getMediaSizeBytes } from '../../utils/mediaItem';
import ImgOrFallback from '../images/ImageOrFallback';
import MediaItemImageFallback from '@/views/admin/mediaLibrary/MediaItemImageFallback';
import { MediaClass } from '../../utils/enums';
import MediaBadges from '@/views/admin/mediaLibrary/MediaBadges';
import Notification from '@/components/util/Notification';

export default {
  name: 'MediaFileCard',
  components: {
    ImgOrFallback,
    MediaItemImageFallback,
    MediaBadges,
    ModalMediaPicker: () => import('@/components/modals/ModalMediaPicker.vue')
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    isModal: {
      type: Boolean,
      default: false
    },
    selectedFile: {
      type: Boolean,
      default: false
    },
    showMove: {
      type: Boolean,
      default: true
    },
    isMaster: {
      type: Boolean,
      default: false
    },
    view: {
      type: String,
      default: 'tile'
    }
  },
  data () {
    return {
      height: '45px',
      width: '85px',
      show: false,
      submit: false,
      orgId: this.$route.params.id
    }
  },
  computed: {
    isMediaBundle () { return isBundle( this.item ); },
    id () {
      return this.item?.id;
    },
    mainMedia () {
      return this.isMediaBundle ? this.item?.masterVideo : this.item;
    },
    displayTitle () {
            return this.isMediaBundle
                ? `☆ ${this.item.name}`
                : this.item?.title || this.item?.filename || this.$t( 'tileMediaItem.missingBrackets' );
    },
    typeAndResolutionText () {
            return [ // return an empty string if neither value is defined
                this.isMaster ? this.$t( 'enums.MediaFormats.Master' ) :this.mediaClassTypeText,
                this.resolutionText
            ].filter( s => s ).join( ', ' );
    },
    resolutionText () {
            return getMediaResolutionLabel( this.mainMedia, '' ) || '';
        },

    mediaClassTypeText () {
            const transcodeType = this.mainMedia?.mediaClass === MediaClass.VIDEO && getMediaTranscodeType( this.mainMedia );

            // only show video type if it can be detected
            return transcodeType
                ? this.$t( `transcodeFormat.${transcodeType}` )
                : this.$t( `enums.MediaClass.${this.mainMedia.mediaClass}` );
    },

    hasSize () {
      return this.mainMedia.mediaClass !== MediaClass.LIVE
          && getMediaSizeBytes( this.mainMedia );
    },
    logo () {
      return this.mainMedia?.thumbnail?.url
    },
    toMedia () {
      return { name: 'MediaBundle', params: { id: this.orgId, bundleId: this.item?.id } };
    },
    toMediaItem () {
      return { name: 'MediaItem', params: { id: this.orgId, mediaId: this.item?.id } };
    }
  },
  methods: {
    getMediaSizeBytes,
    async deleteFile () {
        const id = this.item.id;
        if ( this.view === 'tile') {
          setTimeout( function() {
              window.UIkit.dropdown(`#delete-dropdown-${id}`).hide();
          }, 1000 );
        }
        if ( this.mainMedia.linkedContent?.length || this.mainMedia.assets?.length ) {
          window.UIkit.modal('#info-modal-media-item').show();
        } else {
            window.UIkit.modal.confirm(this.$t( 'DeleteModal.ConfirmDeleteMedia', { name: this.displayTitle }), { labels: {
                cancel: this.$t('Actions.Cancel'), ok: this.$t('Actions.Delete')
            }}).then( async () => {
              const response = this.isMediaBundle ? await this.$store.dispatch('deleteMediaBundle', { mediaId: this.item.id }) : await this.$store.dispatch('deleteMedia', { mediaId: this.mainMedia.id });
              const deletedMediaName = response?.deleteMediaBundleAndContents?.bundle?.name || response?.deleteMedia?.media?.name;
                if ( !response.errors ) {
                  Notification.showNotification(this.$t( 'DeleteModal.Removed'), this.$t( 'DeleteModal.HasBeenRemoved', { name: deletedMediaName }));
                  this.$emit('refetch');
                } else {
                    Notification.showNotification(this.$t( 'DeleteModal.NotRemoved'), response?.errors[0]?.message,'error');
                }
              });
        }
    },
    onClick () {
      this.show = true;
      const id = this.item.id;
          setTimeout( function() {
            window.UIkit.modal(`#media-picker-modal-${id}`).show();
      }, 100 );
    },
    closeModal () {
      const id = this.item.id;
      window.UIkit.modal(`#media-picker-modal-${id}`).hide();
      setTimeout( function() {
        this.show = false;
        }, 100 );
    },
    async moveToFolder ( selectedFolder ) {
      var response;
      this.submit = true;
      if( this.isMediaBundle )
        response = await this.$store.dispatch('updateMediaBundle',{ variables: { folder: this.item.id, moveTo: selectedFolder }})
      else
        response = await this.$store.dispatch('updateMedia', { variables: { media: this.item.id, moveToFolder: selectedFolder }})
      this.submit = false;
      if(response) {
        Notification.showNotification(this.$t( 'DeleteModal.Moved'), this.$t( 'DeleteModal.HasBeenMoved', { name: this.displayTitle }));
        this.closeModal();
        this.$emit('refetch');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.uk-width-small {
  width: 215px;
}

.uk-height-small {
    height: 160px;
}

.selected {
  border: 1px solid var(--app-primary-color);
}

.more-options {
  padding: 5px 0px;
}

.meta-data {
  font-size: 10px;
}

.title {
  font-size: 14px;
}
</style>
