<template>
    <div class="uk-flex uk-flex-center">
        <div v-if="isNew" class="uk-margin-small-right">
            <i :uk-tooltip="toolTip('newMedia')" class="bi bi-calendar" style="color: #72ae35;"></i>
        </div>
        <div v-if="isChanged" class="uk-margin-small-right">
            <i :uk-tooltip="toolTip('changed')" class="bi bi-tick" style="color: #0a0add;"></i>
        </div>
        <div v-if="isInProcess" class="uk-margin-small-right">
            <i :uk-tooltip="toolTip('inProcess')" class="bi bi-recycle" style="color: #ba22ff;"></i>
        </div>
        <div v-if="isInUse" class="uk-margin-small-right">
           <i :uk-tooltip="toolTip('inUse')" class="bi bi-cloud" style="color: #e79908;"></i>
        </div>
        <div v-if="isError" class="uk-margin-small-right">
            <i :uk-tooltip="toolTip('inUse')" class="bi bi-caution" style="color: #e79908;"></i>
        </div>
        <div class="type-icon tooltip">
            <MediaItemImageFallback :uk-tooltip="toolTipMediaClass" type="icon" isMediaBadge :media="media" class="type-icon" />
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import MediaItemImageFallback from '@/views/admin/mediaLibrary/MediaItemImageFallback';

export default {
    name: 'MediaBadges',
    props: {
        media: { type: Object, required: true }
    },
    components: {
        MediaItemImageFallback
    },
    computed: {
        isInUse () {
            return this.media?.linkedContent?.length > 0 || this.media?.assets?.length > 0;
        },
        isNew () {
            return moment( this.media.createdAt ).isAfter( moment().subtract( 3, 'days' ).startOf( 'day' ) );
        },
        isChanged () {
            if ( !this.transcodeStatus( 'IN_PROGRESS' ) && !this.transcodeStatus( 'FAILED' ) ) {
                return moment( this.media.transcodeDateRange?.end ).isAfter( moment().subtract( 3, 'days' ).startOf( 'day' ) );
            }
            return false;
        },
        isInProcess () {
            return !this.transcodeStatus( 'FAILED' ) ? this.transcodeStatus( 'IN_PROGRESS' ) : false;
        },
        isError () {
            return this.transcodeStatus( 'FAILED' );
        },
        toolTipMediaClass () {
            return this.$t( `enums.MediaClass.${this.media.mediaClass}` );
        }
    },
    methods: {
        transcodeStatus ( status ) {
            return this.isMediaTranscodeStatus( this.media, status )
                || this.isMediaChildsTranscodeStatus( this.media, status );
        },
        isMediaTranscodeStatus ( media, status ) {
            return media.transcodeStatus === status;
        },
        isMediaChildsTranscodeStatus ( media, status ) {
            return ( media.transcodes || []).some( mediaChild => this.isMediaTranscodeStatus( mediaChild, status ) );
        },
        toolTip ( badgeType ) {
            return this.$t( `mediaBadges.${badgeType}` )
        }
    }

};
</script>
